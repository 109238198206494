import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes,
  base: process.env.BASE_URL
})

// router.beforeEach(beforeEach)

export default router

// async function beforeEach (to, from, next) {
//   // console.log(this.route)
//   // console.log(to)
//   // console.log(
//   //   router.app.$nextTick(() => console.log(router.app.$children[0].setLayout('frame')))
//   // )
//   // router.app.setLayout('')
//   // console.log(router.app.$children)
//   // router.app.$nextTick(() => router.app.$children[0].setLayout('frame'))
//   // console.log(router.app.$children[0])
//   // console.log(next)
//   next()
// }
