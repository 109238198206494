<template lang="pug">
#app
  .container.mx-auto
    nav.flex.bg-white.flex-wrap.items-center.justify-between.p-4.mb-4
      .w-auto(class='lg:order-2 lg:w-1/5 lg:text-center')
        router-link.text-xl.text-gray-800.font-semibold.font-heading(to='/')
          | KONUK
      .block(class='lg:hidden')
        button.navbar-burger.flex.items-center.py-2.px-3.text-indigo-500.rounded.border.border-indigo-500(@click="navIsOpen = !navIsOpen")
          svg.fill-current.h-3.w-3(viewbox='0 0 20 20' xmlns='http://www.w3.org/2000/svg')
            title Menu
            path(d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z')
      .navbar-menu.w-full(class='lg:order-1 lg:block lg:w-2/5' :class="navIsOpen ? 'navOpen' : 'navClosed'")
        router-link.navbar-menu-link(to='/') Home
        router-link.navbar-menu-link(to='/transfer') Transfer
      .navbar-menu.w-full(class='lg:order-3 lg:block lg:w-2/5 lg:text-right' :class="navIsOpen ? 'navOpen' : 'navClosed'")
        router-link.navbar-menu-link(to='/about') About
  .container.mx-auto
    router-view

</template>

<style lang="scss">
a.navbar-menu-link{
  @apply  block mt-4 text-black lg:inline-block lg:mt-0 hover:text-gray-900 uppercase;
  &:not(:last-child) {
    @apply mr-4;
  }
}

@media (max-width: 1023px) {
  .navClosed {
    transition: max-height 0.2s ease-in-out 0.2s, opacity 0.2s ease-in-out 0s;
    max-height: 0px;
    opacity: 0;
  }

  .navOpen {
    transition: max-height 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0.3s;
    max-height: 200px;
    opacity: 1;
  }
}
</style>

<script>
export default {
  data () {
    return {
      navIsOpen: false
    }
  },
  created () {
    if (window.innerWidth < 1024) {
      this.navIsOpen = false
    }
  }
}
</script>
