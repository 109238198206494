import Cookies from 'js-cookie'
import axios from 'axios'

// state
export const state = {
  currency: Cookies.get('currency') || 'EUR',
  currencies: [],
  currencyRates: [],
  requestUrl: 'www.konuk.com'
}

// getters
export const getters = {
  currency: state => state.currency,
  currencies: state => state.currencies,
  currencyRates: state => state.currencyRates,
  requestUrl: state => state.requestUrl
}

// mutations
export const mutations = {
  SET_REQUEST_URL (state, url) {
    state.requestUrl = url
  },
  SET_CURRENCY (state, { currency }) {
    state.currency = currency
  },
  SET_BASE_DATA (state, { data }) {
    state.currency = data.data.currency
    state.currencies = data.data.currencies
    state.currencyRates = data.data.currencyRates
  }
}

// actions
export const actions = {

  fetchCurrency ({ commit }, { currency }) {
    commit('setCurrency', { currency })
    Cookies.set('currency', currency, { expires: 365 })
  },

  async fetchBaseData ({ commit }) {
    try {
      const { data } = await axios.get('/external-transfer/v1/base')
      commit('SET_BASE_DATA', { data: data })
      return data
    } catch (e) {
      console.log('SYSTEM ERROR: PLEASE TRY AGAIN')
    }
  }

}
