<template>
  <div id="app">
    <vue-extend-layouts layout="default" />
  </div>
</template>

<script>
import VueExtendLayouts from 'vue-extend-layout'
import { mapActions, mapMutations } from 'vuex'

const requireContext = require.context('@layouts', false, /.*\.vue$/)

const layouts = requireContext.keys()
  .map(file =>
    [file.replace(/(^.\/)|(\.vue$)/g, ''), requireContext(file)]
  )
  .reduce((components, [name, component]) => {
    components[name] = component.default || component
    return components
  }, {})

export default {
  name: 'App',
  components: { VueExtendLayouts },
  data: () => ({
    layout: null,
    defaultLayout: 'default'
  }),
  beforeMount () {
    this.setBaseData()
    this.setRequestUrl()
  },
  mounted () {
    // this.$loading = this.$refs.loading
    // EXTERNAL DEV TOOLS
    // const recaptchaScript = document.createElement('script')
    // recaptchaScript.setAttribute('src', 'http://localhost:8098')
    // document.head.appendChild(recaptchaScript)
  },
  methods: {
    /**
     * Set the application layout.
     *
     * @param {String} layout
     */
    ...mapMutations({
      SET_REQUEST_URL: 'currency/SET_REQUEST_URL'
    }),
    ...mapActions({
      setBaseData: 'currency/fetchBaseData'
    }),

    setRequestUrl () {
      var url = (window.location !== window.parent.location) ? document.referrer : document.location.href
      console.log(url)
      this.SET_REQUEST_URL(url)
    },

    setLayout (layout) {
      if (!layout || !layouts[layout]) {
        layout = this.defaultLayout
      }

      this.layout = layouts[layout]
    }
  }
}
</script>
