// import Home from '@views/Home.vue'

function loadComponent (name) { return import(/* webpackChunkName: "view-" */ `@views/${name}.vue`) }

const routes = [
  // KONUK
  {
    path: '/',
    name: 'index',
    component: () => loadComponent('transfer/Index')
  },
  // {
  //   path: '/transfer',
  //   name: 'transfer.index',
  //   component: () => loadComponent('transfer/Index')
  // },
  // {
  //   path: '/transfer/routes/:from/:to',
  //   name: 'transfer.routes',
  //   component: () => loadComponent('transfer/Routes')
  // },
  // children: [
  //   {
  //     path: '/transfer/routes/:from/:to',
  //     name: 'transfer.routes',
  //     component: () => loadComponent('transfer/Routes')
  //   }
  // ]
  {
    path: '/transfer',
    name: 'transfer.index',
    component: () => loadComponent('transfer/Index')
  },
  {
    path: '/transfer/routes/:from/:to',
    name: 'transfer.routes',
    component: () => loadComponent('transfer/Routes')
  },
  {
    path: '/transfer/booking/:uuid',
    name: 'transfer.booking',
    component: () => loadComponent('transfer/Checkout')
  },
  // Kredi kartı ile ödeme ekranı. Girilen bilgiler geçerli sayılır
  {
    path: '/checkout/:uuid',
    name: 'transfer.checkout',
    component: () => loadComponent('transfer/Checkout')
  },
  // Kredi kartı ödemesi sonrasında onay ekranları.
  {
    path: '/confirmation/:uuid',
    name: 'transfer.confirmation',
    component: () => loadComponent('transfer/Checkout')
  },
  // VOUCHER
  {
    path: '/voucher/:uuid',
    name: 'voucher',
    component: () => loadComponent('transfer/Voucher')
  },
  {
    path: '/about',
    name: 'about',
    component: () => loadComponent('About')
  }
  // {
  //   path: '/transfer',
  //   name: 'konuk.transfer',
  //   component: () => loadComponent('Transfer'),
  //   meta: {
  //     layout: 'frame'
  //   }
  // },

  // PARTNER
  // {
  //   path: '/:partner',
  //   name: 'partner.index',
  //   component: Home
  // },
  // {
  //   path: '/:partner/about',
  //   name: 'partner.about',
  //   component: () => loadComponent('About')
  // },
  // {
  //   path: '/:partner/transfer',
  //   name: 'partner.transfer',
  //   component: () => loadComponent('Transfer'),
  //   meta: {
  //     layout: 'frame'
  //   }
  // }

  //   // Otherwise
  //   { path: '/401', component: page('errors/401'), name: '401' },
  //   { path: '*', component: page('errors/404') }
]

export default routes
