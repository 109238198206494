<template lang="pug">
#app
  router-view
  footer
    .container
      .tech
        img(src="@/assets/secure.png", style="width: 120px")
        img(src="@/assets/visa.svg")
        img(src="@/assets/mastercard.svg")
      img.powered(src="@/assets/logo-wording.svg", style="width: 200px")
      .awards
        img.powered(
          src="@/assets/powered-by-sura-hotels.png",
          style="width: 250px"
        )
</template>

<style lang="scss">
html,
body {
  background-color: #f6f7f8;
}
* {
  outline: none;
}
footer {
  background-color: #565555;
  padding: 20px 0;
  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .tech {
      display: flex;
      flex-direction: row;
      img:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
@media (max-width: 768px) {
  footer {
    .container {
      flex-direction: column;
      .tech {
        // flex-direction: column;
        img {
          margin: 0;
        }
      }
      img.powered {
        margin-top: 20px;
        width: 160px !important;
      }
    }
  }
}
</style>

<script>
export default {
  data() {
    return {
      navIsOpen: false,
    };
  },
  created() {
    if (window.innerWidth < 1024) {
      this.navIsOpen = false;
    }
  },
  mounted() {
    setInterval(function () {
      window.parent.postMessage(
        { success: true, data: document.body.scrollHeight + "px" },
        "*"
      );
    }, 1000);
  },
};
</script>
